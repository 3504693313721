<template>
  <div class="Merchantlistpar">
    <div class="top-titles">
      <div class="title">
          <span>商家列表</span>/商家详情
      </div>
     
     <div class="top-box">
          <div class="top-head">
            <div class="top-head-img">
            <img v-if="listpar.urlImage !== ''" :src="listpar.urlImage" alt="" />
            <img v-if="listpar.urlImage == ''" src="../../assets/image/touxiang.png" alt="" />
            </div>
            <span>{{listpar.companyName}}</span>
          </div>
          <div class="top-money">

          </div>
     </div>
    </div>

    <!-- 详情-->
    <div class="particulars">
      <div class="particulars-box">
          <div class="particulars-list" ><span class="pl-tltle">公司名称:</span><span class="pl-content">{{listpar.companyName}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">联系人:</span><span class="pl-content">{{listpar.contacts}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">联系手机号:</span><span class="pl-content">{{listpar.cellPhone}}</span></div>
           <!-- <div class="particulars-list" ><span class="pl-tltle">管理员手机号:</span><span class="pl-content">{{listpar.linker_phone}}</span></div> -->
          <div class="particulars-list" ><span class="pl-tltle">公司地址:</span><span class="pl-content">{{listpar.company_addr}}</span></div>
          <!-- <div class="particulars-list" ><span class="pl-tltle">性别:</span><span class="pl-content">{{listpar.Gender}}</span></div> -->
          <!-- <div class="particulars-list" ><span class="pl-tltle">年龄:</span><span class="pl-content">{{listpar.age}}</span></div> -->
          <div class="particulars-list" ><span class="pl-tltle">注册时间:</span><span class="pl-content">{{listpar.registration}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">统一社会信用代码:</span><span class="pl-content">{{listpar.uniformCreditCode}}</span></div>
          <!-- <div class="particulars-list" ><span class="pl-tltle">发布工单数:</span><span class="pl-content">{{listpar.ordersDot}}</span></div> -->
          <!-- <div class="particulars-list" ><span class="pl-tltle">报名人数:</span><span class="pl-content">{{listpar.registrationson}}</span></div> -->
          <!-- <div class="particulars-list" ><span class="pl-tltle">结算人数:</span><span class="pl-content">{{listpar.finalStatement}}</span></div> -->
          <!-- <div class="particulars-list" ><span class="pl-tltle">取消人数:</span><span class="pl-content">{{listpar.cancel}}</span></div> -->
          <div class="particulars-list" ><span class="pl-tltle">停用状态:</span><span class="pl-content">{{listpar.stoped}}</span></div>
      </div>

      <div class="particulars-img">
          <div class="particulars-list" >
            <span class="pl-tltle">营业执照:</span>
              <viewer :images="listpar"> 
                        <img :src="listpar.businessImage">
              </viewer>
            </div>
          <div class="particulars-list" >
            <span class="pl-tltle">派遣许可证:</span>
            <viewer :images="listpar"> 
            <img :src="listpar.licenceImage" alt="">
            </viewer>
            </div>

            <div class="particulars-list" >
            <span class="pl-tltle">身份证人像面:</span>
            <viewer :images="listpar"> 
            <img :src="listpar.lawer_idcard_npic" alt="">
            </viewer>
            </div>
          <div class="particulars-list" >
            <span class="pl-tltle">身份证国徽面:</span>
            <viewer :images="listpar"> 
            <img :src="listpar.lawer_idcard_ppic" alt="">
            </viewer>
           </div>
      </div>



        <div class="particulars-illegal">
              <ul>
                <li>违规类型</li>
                <li>拖欠工资</li>
                <li>线下恶意交易</li>
              </ul>
              <ul>
                <li>违规类型解释</li>
                <li>没有及时给平台充佣金或分享佣金</li>
                <li>需线下恶意交易一次停用一周，达到两次列入黑名单 <br> 会员报名了，但是没有来上班，通过打电话确认是线下交易</li>
               
              </ul>
              <ul>
                <li>违规次数</li>
                <li>{{listpar.breakOneTotal}} <el-button  type="primary"
                class="biy-icon" @click="zhengjia('0')" >增加一次</el-button></li>
                <li>{{listpar.breakTwoTotal}} <el-button  type="primary"
                class="biy-icon" @click="zhengjia('1')" >增加一次</el-button></li>
              </ul>
        </div>

  <div class="particulars-affirm">
        <el-form ref="form" label-width="100px">
          <div class="">
          <el-form-item label="请选择原因：" >
               <el-radio-group v-model="radio">
                 <el-radio v-for="(r , i) in radiopar.one" :key="i" :label="r.value">{{r.lable}}</el-radio>
               </el-radio-group>
          </el-form-item>
            </div>
          <div class="">
          <el-form-item label="请选择日期：" >
            <el-date-picker
              v-model="radiopar.time" type="date"
              placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
            </div>
          <div class="">
          <el-form-item label="处罚：" >
            <el-select v-model="listpar.stophanging" placeholder="违规处罚">
              <el-option  v-for="(r, i) in seeklist.two" :key="i" :label="r.label" :value="r.value"></el-option>
            </el-select>
          </el-form-item>
            </div>

          <div class="" style="text-align: center">
           <el-button
                type="primary"
                class="biy-icon" @click="seek()"
                >处罚</el-button>
            </div>
        </el-form>
      </div>

    </div>



  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
export default {
  data() {
    return {
      radio:0,
      // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      // 下拉框
      seeks: {},
      seeklist:fakelist.merchantlistpar,

      listpar:{},
      list:[ ],
      etype:null,

      radiopar:{
         one:[
           {lable:'不发短信',
           value:0,},
           {lable:'拖欠工资',
           value:1,},
           {lable:'线下恶意交易',
           value:2,},
         ],
         time:''
       }
    };
  },

  created(){
    this.getSong();
  },
  mounted() {},

  methods: {

        //弹窗
      zhengjia(e){
          this.etype = e
          this.$confirm('是否确认增加一条记录', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.zhengjias()
          }).catch(action => {
            console.log("取消");
          })
      },

   async zhengjias(){
     let res = await apiurl.entityDataHandle({
        FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
        loginName:this.$common.baseGetuSername(),//登录账号 
        loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'ServicerEntity',
        handleMode:'breakRecord',
        bizId:this.$route.params.id,
        breakType:this.etype,
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
        this.getSong()
      },



    //查询接口
      async getSong(){
			let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        handleMode:'fetch',
        bizCatalog:'ServicerEntity',
        bizId:this.$route.params.id,
        })
       
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.listpar = res.Result;
      },

// 设置状态接口
     async seek(){  
       let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家

        bizCatalog:'ServicerEntity',
        handleMode:'stop',
        bizId:this.listpar.id,
        todo:this.listpar.stophanging,
        breakType:this.radio,
        date:this.radiopar.time
       })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
         this.$alert('设置成功', '消息提示', {confirmButtonText: '确定'});
         this.getSong()
      }
  },

  computed: {},
};
</script>


<style  scoped>
@import "../../assets/css/parstyle.css";

</style>